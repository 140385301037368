import { gql } from 'graphql-request';

// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>bid_mutate'
	},

	form: {
		fieldProps: {
			amount: {
				required: true,
				pattern: /^[0-9]*$/i
			}
		}
	},

	gql: {
		schema: gql`
			query AuctionBidMutate(
				$auction_application_id: ID,
				$real_estate_id: ID,
				$amount: String
			) {
				request(
					auction_application_id: $auction_application_id,
					real_estate_id: $real_estate_id,
					amount: $amount
				) {
					status_id
				}
			}
		`
	}
};

export default request;
