import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/auth';

const AuthContext = createContext();
export default AuthContext;

export function AuthProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		lastAuthenticated: null, // to check if changed!
		authenticated: null, // null = initi state, after check is set true or false
		token: null,
		data: {}, // token (jwt) data

		// User & contact data
		userData: {},
		contactData: {},

		// Quick view auth
		quickViewOpen: false,
		quickViewStep: 'login',
		quickViewCallback: false
	});

	return (<AuthContext.Provider value={{ data, dispatch }}>{children}</AuthContext.Provider>);
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(AuthContext);
