import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'subscription>mutate'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation Subscription(
				$active: Int,
				$subscription_id: ID,
				$type: String,
				$master_table: String,
				$master_id: ID,
				$data: String
			) {
				request(
					active: $active,
					subscription_id: $subscription_id,
					type: $type,
					master_table: $master_table,
					master_id: $master_id,
					data: $data
				) {
					subscription_id
					active
					type
				}
			}
		`
	}
};

export default request;
