import { gql } from 'graphql-request';

// New - use react-query
const request = {
	request: {
		name: 'auction>application'
	},

	form: {},

	gql: {
		schema: gql`
			query AuctionApplication(
				$real_estate_id: ID,
				$auction_application_id: ID
			) {
				request(
					real_estate_id: $real_estate_id,
					auction_application_id: $auction_application_id
				) {
					auction_application_id
					status_id
					status_nm
					status_style
					real_estate_id
					uri_segment
					specifications
					location
					price
					auction_start_dtm
					image_filepath_url
					contact_type_id
					organisation_nm
					organisation_reg_no
					givenname
					surname
					personal_identification_no
					tax_no
					address
					postal_code
					postal_code_nm
					municipality_id
					municipality_nm
					country_iso3
					country_nm
					citizenship
					email
					phone
					role
					role_nm
					share
					share_nm
					person
					auction_organizer
					payment_reference
					payment_purpose
					payment_purpose_code
					payment_qrcode
					enforce_preemption_right
					auction_role_id
					auction_role_nm
					auction_role_behalf_id
					auction_role_behalf_nm
					security_deposit_return_iban
					security_deposit_return_type
					security_deposit_return_bank_nm
					security_deposit_return_bank_bic
					notes
					attachment {
						group
						path_url
						extension
						file_system_id
						file_system_nm_pub
						file_system_nm_sys
						filepath_url
					}
					is_gdpr_agreement
					is_tech_agreement
					is_winner
					live_auction_last_bid
					live_auction_last_bid_amount
					server_dtm
				}
			}
		`
	}
};

export default request;
