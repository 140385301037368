import { useStore as appStore } from '../../contexts/app';

const useApp = () => {
	const { data: appData, dispatch: appDispatch } = appStore();

	const appReset = (reload) => {
		if (process.browser) {
			window.localStorage.removeItem('loader');
			window.localStorage.removeItem('properties');
			window.localStorage.removeItem('lockdown');
			window.localStorage.removeItem('possessid');
			window.localStorage.removeItem('list-scroll-top');

			if (reload) {
				window.location.reload();
			}
		}
	};

	return { appData, appDispatch, appReset };
};

export default useApp;
