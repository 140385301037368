import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'webshop>cart>add'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation CartAdd(
			  $product_codeid: String,
				$quantity: Int
			) {
			  request(
			    product_codeid: $product_codeid
					quantity: $quantity
			  ) {
			    id
			  }
			}
		`
	}
};

export default request;
