import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const HistoryContext = createContext();
export default HistoryContext;

export function HistoryProvider({ children }) {
	const [history, setHistory] = useState([]);
	const { asPath, push, pathname } = useRouter();

	const prevRoute = (unset = false) => {
		for (let i = history.length - 2; i >= 0; i -= 1) {
			const route = history[i];
			if (!route.includes('#') && route !== pathname) {
				// if you want to pop history on back
				if (unset === true) {
					const newHistory = history.slice(0, i);
					setHistory(newHistory);
				}

				return route;
			}
		}

		return null;
	};

	const goBack = () => push(prevRoute(true) || '/');

	useEffect(() => setHistory(previous => [...previous, asPath]), [asPath]);

	return (<HistoryContext.Provider value={{ goBack, prevRoute, history }}>{children}</HistoryContext.Provider>);
}

HistoryProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(HistoryContext);
