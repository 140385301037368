import { useState, useEffect } from 'react';

import useGQL from './useGQL';

const useGQLQuery = (requestName = null, requestPath = null, vars = {}, options = {}) => {
	const [variables, setVariables] = useState(vars || {});

	const [result, setResult] = useState(null); // last result
	const [error, setError] = useState(null); // last error

	const { query } = useGQL(requestPath, requestName);
	const { isFetching: loading, isSuccess: loaded, data, refetch: refetchGQL } = query(variables || {}, options || {});

	useEffect(() => {
		if (typeof options.refetch_on_variable_change === 'undefined' || options.refetch_on_variable_change === true) {
			if (typeof options.refetch_vars_not_empty === 'undefined' || (options.refetch_vars_not_empty && Object.keys(variables).length > 0)) {
				if (options.refetch_reset_result === true) setResult(null); // reset result
				refetch();
			}
		}
	}, [variables]);

	const refetch = () => {
		if (loading === false && refetchGQL) refetchGQL();
	};

	useEffect(() => {
		if (loading === false && loaded === true) {
			setResult((data && data.request) ? data.request : null);
			setError(data.error ? { error: true, errorCode: data.errorCode || null, errorMessage: data.message || 'n/a error message.' } : null);
		}
	}, [loading]);

	return { loading, loaded, data, result, error, refetch, setVariables };
};

export default useGQLQuery;
