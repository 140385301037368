import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'webshop>product_list'
	},

	data: null,

	gql: {
		schema: gql`
			query ProductList {
				request {
					product_codeid
					price
					product_nm_pub
					description
				}
			}
		`
	}
};

export default request;
