import { useStore as loaderStore } from '../../contexts/loader';

const useConfig = () => {
	const { data } = loaderStore();

	const clientConfig = (name) => {
		switch (name) {
		// # IMPORTANT: ANY CHANGE HERE, CHANGE ALSO IN: nextapp/useConfig, deployment/.env.*
		// # GENERAL
		case 'BASE_URL': 									return process.env.NEXT_PUBLIC_BASE_URL;
		case 'PROJECT_NAME': 								return process.env.NEXT_PUBLIC_PROJECT_NAME;
		case 'GOOGLE_MAP_EMBED_APIKEY': 					return process.env.NEXT_PUBLIC_GOOGLE_MAP_EMBED_APIKEY;

		// # ANALYTICS
		case 'GOOGLE_ANALYTICS_ID': 						return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
		case 'FACEBOOK_PIXEL_ID': 							return process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

		// # AUTH
		case 'GOOGLE_OAUTH_CLIENT_ID': 						return process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID;
		case 'FACEBOOK_OAUTH_APP_ID': 						return process.env.NEXT_PUBLIC_FACEBOOK_OAUTH_APP_ID;
		case 'THIRD_PARTY_AUTH_PASSWORD_SALT': 				return process.env.NEXT_PUBLIC_THIRD_PARTY_AUTH_PASSWORD_SALT;

		// # SERVER/API
		case 'SIID': 										return process.env.NEXT_PUBLIC_SIID;
		case 'SIID_NM': 									return process.env.NEXT_PUBLIC_SIID_NM;
		case 'API_KEY': 									return process.env.NEXT_PUBLIC_API_KEY;
		case 'API_URL': 									return process.env.NEXT_PUBLIC_API_URL;
		case 'API_URL_DEV': 								return process.env.NEXT_PUBLIC_API_URL_DEV;

		// # SOCKET
		case 'SOCKET_SERVER_URL':							return process.env.NEXT_PUBLIC_SOCKET_SERVER_URL;

		// # SKIN
		case 'FONT_SIZE_BODY':								return process.env.NEXT_PUBLIC_FONT_SIZE_BODY;
		case 'SKIN_IMG_DIR': 								return process.env.NEXT_PUBLIC_SKIN_IMG_DIR;
		case 'SKIN_LOGO_POSITIVE': 							return process.env.NEXT_PUBLIC_SKIN_LOGO_POSITIVE;
		case 'SKIN_LOGO_POSITIVE_MOBILE': 					return process.env.NEXT_PUBLIC_SKIN_LOGO_POSITIVE_MOBILE;
		case 'SKIN_LOGO_NEGATIVE': 							return process.env.NEXT_PUBLIC_SKIN_LOGO_NEGATIVE;
		case 'SKIN_LOGO_NEGATIVE_MOBILE': 					return process.env.NEXT_PUBLIC_SKIN_LOGO_NEGATIVE_MOBILE;
		case 'SKIN_COLOR_PRIMARY': 							return process.env.NEXT_PUBLIC_SKIN_COLOR_PRIMARY;
		case 'SKIN_COLOR_SECONDARY': 						return process.env.NEXT_PUBLIC_SKIN_COLOR_SECONDARY;
		case 'SKIN_COLOR_BODY': 							return process.env.NEXT_PUBLIC_SKIN_COLOR_BODY;
		case 'SKIN_COLOR_ERROR': 							return process.env.NEXT_PUBLIC_SKIN_COLOR_ERROR;
		case 'SKIN_COLOR_SUCCESS': 							return process.env.NEXT_PUBLIC_SKIN_COLOR_SUCCESS;
		case 'SKIN_BORDER_COLOR': 							return process.env.NEXT_PUBLIC_SKIN_BORDER_COLOR;
		case 'SKIN_BACKGROUND_FORM': 						return process.env.NEXT_PUBLIC_SKIN_BACKGROUND_FORM;
		case 'SKIN_IMG_HERO_WEBP': 							return process.env.NEXT_PUBLIC_SKIN_IMG_HERO_WEBP;
		case 'SKIN_IMG_HERO_FALLBACK': 						return process.env.NEXT_PUBLIC_SKIN_IMG_HERO_FALLBACK;
		case 'SKIN_FONT_FAMILY_PRIMARY': 					return process.env.NEXT_PUBLIC_SKIN_FONT_FAMILY_PRIMARY;
		case 'SKIN_FONT_FAMILY_SECONDARY': 					return process.env.NEXT_PUBLIC_SKIN_FONT_FAMILY_SECONDARY;

		// # CONFIG
		case 'LANDING_HERO_SEARCH':							return process.env.NEXT_PUBLIC_LANDING_HERO_SEARCH === 'true';
		case 'LANDING_LISTING_CNT':							return process.env.NEXT_PUBLIC_LANDING_LISTING_CNT === 'true';
		case 'LANDING_LIST_NEW':							return process.env.NEXT_PUBLIC_LANDING_LIST_NEW === 'true';
		case 'LANDING_LIST_NEW_VIEW_ALL':					return process.env.NEXT_PUBLIC_LANDING_LIST_NEW_VIEW_ALL === 'true';
		case 'LANDING_LIST_BEFORE_START':					return process.env.NEXT_PUBLIC_LANDING_LIST_BEFORE_START === 'true';
		case 'LANDING_LIST_AFTER_START':					return process.env.NEXT_PUBLIC_LANDING_LIST_AFTER_START === 'true';
		case 'LANDING_LIST_ARCHIVED':						return process.env.NEXT_PUBLIC_LANDING_LIST_ARCHIVED === 'true';
		case 'LANDING_LIST_LIVE_INPROGRESS':				return process.env.NEXT_PUBLIC_LANDING_LIST_LIVE_INPROGRESS === 'true';
		case 'PRO_CTA':										return process.env.NEXT_PUBLIC_PRO_CTA === 'true';
		case 'PRO_LOCKED':									return process.env.NEXT_PUBLIC_PRO_LOCKED === 'true';
		case 'FEATURE_GRID':								return process.env.NEXT_PUBLIC_FEATURE_GRID === 'true';
		case 'FILTER_SAVE':									return process.env.NEXT_PUBLIC_FILTER_SAVE === 'true';
		case 'ITEM_SAVE':									return process.env.NEXT_PUBLIC_ITEM_SAVE === 'true';
		case 'ITEM_SUBSCRIBE':								return process.env.NEXT_PUBLIC_ITEM_SUBSCRIBE === 'true';
		case 'OAUTH_GOOGLE':								return process.env.NEXT_PUBLIC_OAUTH_GOOGLE === 'true';
		case 'OAUTH_FACEBOOK':								return process.env.NEXT_PUBLIC_OAUTH_FACEBOOK === 'true';
		case 'DETAIL_FAQ':									return process.env.NEXT_PUBLIC_DETAIL_FAQ === 'true';
		case 'DETAIL_CHECKLIST':							return process.env.NEXT_PUBLIC_DETAIL_CHECKLIST === 'true';
		case 'DETAIL_ANALYSIS':								return process.env.NEXT_PUBLIC_DETAIL_ANALYSIS === 'true';
		case 'HELP_ENABLED':								return process.env.NEXT_PUBLIC_HELP_ENABLED === 'true';
		case 'GENERAL_LIST':								return process.env.NEXT_PUBLIC_GENERAL_LIST === 'true';
		case 'INAPP_NOTIFICATIONS':							return process.env.NEXT_PUBLIC_INAPP_NOTIFICATIONS === 'true';
		default: 											return null;
		}
	};

	// ex. module>bidding>enabled
	const serverConfig = (key) => {
		if (key) {
			const c = data.config || {};
			if (key === '*') return c;

			if (key.indexOf('>') !== -1) {
				let r = c;

				key.split('>').map((k) => {
					if (r[k]) {
						r = r[k];
					}

					return true;
				});

				return r;
			}

			return (key && c && c[key]) ? c[key] : {};
		}

		return {};
	};

	return { clientConfig, serverConfig };
};

export default useConfig;
