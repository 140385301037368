import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'markdown>page'
	},

	gql: {
		schema: gql`
			query MarkdownPage(
				$filename: String
			  $filename_prefix: String
			  $filenames: [String]
			) {
				request(
					filename: $filename,
			    filenames: $filenames,
			    filename_prefix: $filename_prefix
				) {
					html
			    meta
				}
			}
		`
	}
};

export default request;
