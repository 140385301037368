import { parseJwt } from '../helpers/jwt';

const reducer = (state, action) => {
	switch (action.type) {
	case 'LOGIN': {
		const { payload } = action;
		const { token } = payload;

		return { ...state, authenticated: true, token, data: parseJwt(token, 'data') };
	}

	case 'SET_LAST_AUTHENTICATED': {
		const { payload } = action;
		return { ...state, lastAuthenticated: payload };
	}

	case 'NOT_AUTHENTICATED': {
		return { ...state, authenticated: false, token: null, data: {} };
	}

	case 'SET_USER_DATA': {
		const { payload } = action;
		return { ...state, userData: payload };
	}

	case 'SET_CONTACT_DATA': {
		const { payload } = action;
		return { ...state, contactData: payload };
	}

	case 'REFRESH_TOKEN': {
		const { payload } = action;
		const { token } = payload;

		return { ...state, token, data: parseJwt(token, 'data') };
	}

	case 'LOGOUT': {
		return { ...state, authenticated: false, token: null, data: {} }; // authenticated: null = "init" state
	}

	// Quick view

	case 'QUICK_VIEW_TOGGLE': {
		const isOpen = !state.quickViewOpen;
		return { ...state, quickViewOpen: isOpen, quickViewCallback: isOpen ? state.quickViewStep : false };
	}

	case 'QUICK_VIEW_SET_STEP': {
		const { payload } = action;
		const { step, callback } = payload;

		return { ...state, quickViewOpen: true, quickViewStep: step, quickViewCallback: callback || false };
	}

	default:
		return state;
	}
};

export default reducer;
