const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_PAID_ACCESS_CALLOUT': {
		const { payload } = action;
		return { ...state, paidAccessCallout: payload };
	}

	case 'SET_IS_JWT_TOKEN_VALID_SERVER': {
		const { payload } = action;
		return { ...state, isJwtTokenValidServer: payload };
	}

	default:
		return state;
	}
};

export default reducer;
