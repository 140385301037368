import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'payment>init'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation PaymentInit(
				$gateway: String,
				$method: String,
				$amount: String,
			) {
				request(
					gateway: $gateway,
					method: $method,
					amount: $amount
				) {
					redirect_url
				}
			}
		`
	}
};

export default request;
