import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>item_prefill'
	},

	gql: {
		schema: gql`
			query AuctionItemPrefill(
				$real_estate_id: ID
				) {
				request(
					real_estate_id: $real_estate_id
				) {
					real_estate_id
					transaction_id
					type_id
					subtype_id
					geo_country_iso3
					geo_location_level1_id
					geo_location_level3_id
					geo_location_level4_id
					address
					postal_code_id
					latitude
					longitude
					codeid
					geodeticid
					size
					size_land_toggle
					size_land
					year_build
					year_adaptation
					floor_toggle
					floor_id
					floor_cnt_id
					storey
					description_short
					description
					energy_certificate_id
					auction_source_id
					auction_sequence_id
					auction_status_id
					auction_offer_type_id
					price
					security_deposit_toggle
					security_deposit
					security_deposit_due_dt
					auction_share_sale
					auction_start_dt
					auction_start_time
					auction_location
					auction_valuation_value
					auction_valuation_dtm
					contact
					nearby {
			      nearby_type_id
			      distance
			    }
					real_estate_additional
			    real_estate_luxury
			    real_estate_room
			    real_estate_heating
			    real_estate_connector
			    auction_additional
			    image {
			      path_url
			      file_system_nm_pub
			      file_system_nm_sys
						filepath_url
						extension
			    }
			    document {
			      path_url
			      file_system_nm_pub
			      file_system_nm_sys
						filepath_url
						extension
			    }
			  }
			}
		`
	}
};

export default request;
