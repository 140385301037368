import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'filter>items'
	},

	gql: {
		schema: gql`
			query FilterItems {
				request {
			    real_estate_filter_id
					real_estate_filter_nm
					result_cnt
					subtype_id
					type_id
					geo_location_level1_id
					geo_location_level2_id
					geo_location_level3_id
					price
					auction_expire_soon
					auction_offer_type
					auction_sequence
					auction_share_sale_all
				}
			}
		`
	}
};

export default request;
