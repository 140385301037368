import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'subscription>subscriptions'
	},

	data: null,

	gql: {
		schema: gql`
			query Subscriptions(
			  $active: Int,
				$subscription_id: ID,
				$type: String,
				$master_table: String,
				$master_id: ID
			) {
				request(
			    active: $active,
					subscription_id: $subscription_id
					type: $type,
					master_table: $master_table,
					master_id: $master_id
				) {
			    active
					subscription_id
					type
			    master_table
			    master_id
				}
			}

		`
	}
};

export default request;
