import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>delete'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionDelete(
				$real_estate_id: ID
			) {
				request(
					real_estate_id: $real_estate_id
				) {
					status
				}
			}
		`
	}
};

export default request;
