import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'payment>gateway>recurring_action'
	},

	gql: {
		schema: gql`
			mutation RecurringAction(
			  $gateway: String,
				$action: String,
			  $transaction_ref: String,
			  $provider_paymentid: String,
			  $provider_schedule_id: String
			) {
				request(
			    gateway: $gateway,
					action: $action,
			    transaction_ref: $transaction_ref,
			    provider_paymentid: $provider_paymentid,
			    provider_schedule_id: $provider_schedule_id
				) {
					transaction_ref
				  provider
					provider_schedule_id
					provider_schedule_status
				  provider_paymentid
				  provider_paymentid_init
				  document_ref
				  product_nm
				  product_codeid
					modified_dtm
				}
			}
		`
	}
};

export default request;
