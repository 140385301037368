import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'payment>transaction_last'
	},

	data: null,

	gql: {
		schema: gql`
      query TransactionLast {
      	request {
      	transaction_ref
				transaction_status
				provider
      	provider_schedule_id
      	provider_schedule_status
        provider_paymentid
        provider_paymentid_init
        document_ref
        product_nm
        product_codeid
      	modified_dtm
      	}
      }
		`
	}
};

export default request;
