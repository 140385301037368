import { useStore as loaderStore } from '../../contexts/loader';

import optimisticCache from '../../data/loader-optimistic-cache/language';

const useLanguage = () => {
	const { data } = loaderStore();
	const { language, isLanguageLoaded } = data;

	const t = (key) => {
		if (isLanguageLoaded) { // loaded from local storage or server
			return language[key] || '';
		}

		return optimisticCache[key] || '';
	};

	return { isLanguageLoaded, t };
};

export default useLanguage;
