import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';

import { useStore } from '../../contexts/user';
import useGQL from './useGQL';

const useJWT = () => {
	const { data: { isJwtTokenValidServer }, dispatch: userDispatch } = useStore();
	const [accessTokenNm] = useState('access_token');
	const [cookies] = useCookies([accessTokenNm]);

	const getJWTToken = () => typeof window === 'undefined' ? cookies[accessTokenNm] : window.localStorage.getItem(accessTokenNm);

	// Verify user token (@ server)
	const { query } = useGQL('auth>user_token_verify');
	const { isFetching, isSuccess, data } = query({ token: getJWTToken() }, { enabled: (getJWTToken() !== null && isJwtTokenValidServer === null) });

	useEffect(() => {
		if (isFetching === false && isSuccess === true) {
			userDispatch({ type: 'SET_IS_JWT_TOKEN_VALID_SERVER', payload: data.request && data.request.status === true });
		}
	}, [isFetching]);

	const parseJwt = () => {
		const token = getJWTToken();

		if (token) {
			return jwt_decode(token);
		}

		return null;
	};

	const getJWTData = (token) => {
		if (token) {
			const d = parseJwt(token);

			if (d !== null && d.data) {
				return d.data;
			}
		}

		return {};
	};

	const isJwtTokenValidClient = () => {
		const token = parseJwt();

		if (token !== null) { // token exist, but must be validated
			if (!token.exp) return false; // token is "broken"

			// Check, if token expired
			const currentDate = new Date();
			return token.exp * 1000 > currentDate.getTime();
		}

		return null; // token not exist, user not authorized.
	};

	return { getJWTToken, parseJwt, getJWTData, isJwtTokenValidClient, isJwtTokenValidServer };
};

export default useJWT;
