import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>mutate'
	},

	validation: {
		// Step 0: Hidden
		auction_status_id: {
			required: true,
			validator: null
		},

		auction_source_id: {
			required: true,
			validator: null
		},

		latitude: {
			required: false,
			validator: null,
			nullifempty: true
		},

		longitude: {
			required: false,
			validator: null,
			nullifempty: true
		},

		is_street_view: {
			required: false,
			validator: null,
			nullifempty: true
		},

		// Step 1: Type
		transaction_id: {
			required: true,
			validator: null,
			step: 1
		},

		// Step 2: General
		type_id: {
			required: true,
			validator: null,
			step: 2
		},
		subtype_id: {
			required: true,
			validator: null,
			step: 2
		},
		geo_country_iso3: {
			required: true,
			validator: null,
			step: 2
		},
		geo_location_level1_id: {
			required: true,
			validator: null,
			step: 2
		},
		/*
		geo_location_level2_id: {
			required: true,
			validator: null,
			step: 2
		},
		*/
		geo_location_level3_id: {
			required: true,
			validator: null,
			step: 2
		},
		geo_location_level4_id: {
			required: true,
			validator: null,
			step: 2
		},
		address: {
			required: true,
			validator: null,
			step: 2
		},
		postal_code_id: {
			required: true,
			validator: null,
			step: 2
		},

		// Step 3: Description
		codeid: {
			required: true,
			validator: null,
			step: 3
		},

		geodeticid: {
			required: true,
			validator: null,
			step: 3
		},

		size: {
			required: true,
			validator: null,
			rules: [
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			step: 3
		},

		size_land_toggle: {
			required: false,
			validator: null,
			falseIfEmpty: true,
			step: 3
		},

		size_land: {
			requiredif: 'size_land_toggle',
			validator: null,
			rules: [
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			nullifempty: true, // db expect decimal
			step: 3
		},

		year_build: {
			required: true,
			rules: [
				{
					name: 'length',
					value: 4,
					tId: 'validationLength'
				},
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			step: 3
		},

		year_adaptation: {
			required: true,
			rules: [
				{
					name: 'length',
					value: 4,
					tId: 'validationLength'
				},
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			step: 3
		},

		floor_toggle: {
			required: false,
			validator: null,
			falseIfEmpty: true,
			step: 3
		},

		floor_id: {
			requiredif: 'floor_toggle',
			validator: null,
			nullifempty: true, // db expect decimal
			step: 3
		},

		floor_cnt_id: {
			required: false,
			validator: null,
			nullifempty: true, // db expect decimal
			step: 3
		},

		storey: {
			required: false,
			validator: null,
			step: 3
		},

		description_short: {
			required: true,
			validator: null,
			step: 3
		},

		description: {
			required: true,
			validator: null,
			step: 3
		},

		energy_certificate_id: {
			required: true,
			validator: null,
			step: 3
		},

		real_estate_additional: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 3
		},

		real_estate_connector: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 3
		},

		real_estate_heating: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 3
		},

		real_estate_luxury: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 3
		},

		real_estate_room: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 3
		},

		nearby: {
			required: false,
			validator: null,
			collector: true,
			step: 3
		},

		image: {
			required: false,
			validator: null,
			collector: true,
			step: 3
		},

		// Step 4: Detail
		auction_sequence_id: {
			required: true,
			validator: null,
			step: 4
		},

		auction_offer_type_id: {
			required: true,
			validator: null,
			step: 4
		},

		price: {
			required: true,
			validator: null,
			rules: [
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			step: 4
		},

		security_deposit_toggle: {
			required: false,
			validator: null,
			falseIfEmpty: true,
			step: 4
		},

		security_deposit: {
			required: false,
			validator: null,
			rules: [
				{
					name: 'numeric',
					tId: 'validationNumeric'
				}
			],
			nullifempty: true, // db expect decimal
			step: 4
		},

		security_deposit_due_dt: {
			required: false,
			validator: null,
			step: 4
		},

		auction_valuation_value: {
			required: false,
			validator: null,
			step: 4
		},

		auction_valuation_dtm: {
			required: false,
			validator: null,
			step: 4
		},

		auction_share_sale: {
			required: true,
			validator: null,
			step: 4
		},

		auction_start_dt: {
			required: true,
			validator: null,
			step: 4
		},

		auction_start_time: {
			required: true,
			validator: null,
			step: 4
		},

		auction_location: {
			required: true,
			validator: null,
			step: 4
		},

		contact: {
			required: true,
			validator: null,
			step: 4
		},

		auction_additional: {
			required: false,
			validator: null,
			collector: true, // array of checkbox values
			step: 4
		},

		document: {
			required: false,
			validator: null,
			collector: true,
			step: 4
		}
	},

	gql: {
		schema: gql`
			mutation AuctionSave(
				$real_estate_id: Int,
				$codeid: String,
				$geodeticid: String,
				$transaction_id: ID,
				$type_id: ID,
				$subtype_id: ID,
				$geo_country_iso3: ID,
				$geo_location_id: ID,
				$geo_location_level1_id: ID,
				$geo_location_level2_id: ID,
				$geo_location_level3_id: ID,
				$geo_location_level4_id: ID,
				$address: String,
				$postal_code_id: ID,
				$latitude: String,
				$longitude: String,
				$size_neto: String,
				$size_bruto: String,
				$size: String,
				$size_land_toggle: Boolean
				$size_land: String,
				$year_build: Int,
				$year_adaptation: Int,
				$floor_toggle: Boolean,
				$floor_id: ID,
				$floor_cnt_id: ID,
				$storey: String,
				$energy_certificate_id: ID,
				$polygon_building: String,
				$polygon_land: String,
				$description_short: String,
				$description: String,
				$price: String,
				$is_street_view: Int,
				$security_deposit_toggle: Boolean,
				$security_deposit: String,
				$security_deposit_due_dt: String,
				$auction_status_id: ID,
				$auction_sequence_id: ID,
				$auction_offer_type_id: ID,
				$auction_source_id: ID,
				$auction_start_dt: String,
				$auction_start_time: String,
				$auction_location: String,
				$auction_share_sale: String,
				$auction_valuation_value: String,
				$auction_valuation_dtm: String,
				$contact: String,
				$nearby: [AuctionNearbyInput],
				$siblings: [RealEstateInput],
				$disclaimer: [AuctionDisclaimerInput],
				$image: [AuctionFileInput],
				$document: [AuctionFileInput],
				$auction_additional: [ID],
			  $real_estate_additional: [ID],
			  $real_estate_luxury: [ID],
			  $real_estate_room: [ID],
			  $real_estate_connector: [ID]
				$real_estate_heating: [ID]
			) {
				request(
					real_estate_id: $real_estate_id,
					codeid: $codeid,
					geodeticid: $geodeticid,
					transaction_id: $transaction_id,
					type_id: $type_id,
					subtype_id: $subtype_id,
					geo_country_iso3: $geo_country_iso3,
					geo_location_id: $geo_location_id,
					geo_location_level1_id: $geo_location_level1_id,
					geo_location_level2_id: $geo_location_level2_id,
					geo_location_level3_id: $geo_location_level3_id,
					geo_location_level4_id: $geo_location_level4_id,
					address: $address,
					postal_code_id: $postal_code_id,
					latitude: $latitude,
					longitude: $longitude,
					size_bruto: $size_bruto,
					size_neto: $size_neto,
					size: $size,
					size_land_toggle: $size_land_toggle,
					size_land: $size_land,
					year_build: $year_build,
					year_adaptation: $year_adaptation,
					floor_toggle: $floor_toggle,
					floor_id: $floor_id,
					floor_cnt_id: $floor_cnt_id,
					storey: $storey,
					energy_certificate_id: $energy_certificate_id,
					polygon_building: $polygon_building,
					polygon_land: $polygon_land,
					description_short: $description_short,
					description: $description,
					price: $price,
					is_street_view: $is_street_view,
					security_deposit_toggle: $security_deposit_toggle,
					security_deposit: $security_deposit,
					security_deposit_due_dt: $security_deposit_due_dt,
					auction_status_id: $auction_status_id,
					auction_sequence_id: $auction_sequence_id,
					auction_offer_type_id: $auction_offer_type_id,
					auction_source_id: $auction_source_id,
					auction_start_dt: $auction_start_dt,
					auction_start_time: $auction_start_time,
					auction_location: $auction_location,
					auction_share_sale: $auction_share_sale,
					auction_valuation_value: $auction_valuation_value,
					auction_valuation_dtm: $auction_valuation_dtm,
					contact: $contact,
					nearby: $nearby,
					siblings: $siblings,
					disclaimer: $disclaimer,
					image: $image,
					document: $document,
					auction_additional: $auction_additional,
			    real_estate_additional: $real_estate_additional,
			    real_estate_luxury: $real_estate_luxury,
			    real_estate_room: $real_estate_room,
			    real_estate_connector: $real_estate_connector,
					real_estate_heating: $real_estate_heating
				) {
					real_estate_id
					codeid
					uri_segment
				}
			}
		`
	}
};

export default request;
