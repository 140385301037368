const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_SIID': {
		const { payload } = action;
		return { ...state, siid: payload.siid, siidNm: payload.siidNm };
	}			
		
	case 'SET_LAST_AUTH_STATUS': {
		const { payload } = action;
		return { ...state, lastAuthStatus: payload };
	}	
			
	case 'SET_HEADER_DATA': {
		const { payload } = action;
		return { ...state, header: payload };
	}

	case 'SET_FOOTER_DATA': {
		const { payload } = action;
		return { ...state, footer: payload };
	}
	
	case 'SET_SOCIAL_LINKS': {
		const { payload } = action;
		return { ...state, socialLinks: payload };
	}	

	default:
		return state;
	}
};

export default reducer;
