import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auth>account_confirmation'
	},

	gql: {
		schema: gql`
			mutation AccountConfirmation(
				$hash: String
			) {
			  request(
					hash: $hash
			  ) {
			    username
			    contact_nm
					access_token
			  }
			}
		`
	}
};

export default request;
