const reducer = (state, action) => {
	switch (action.type) {
	// == Common
	case 'SET_PROPERTIES': {
		const { payload } = action;
		return { ...state, properties: payload };
	}

	case 'PUSH_TO_HISTORY': {
		const { payload } = action;
		return { ...state, history: [...state.history, payload] };
	}

	case 'SET_OVERLAY_PANEL': {
		const { payload } = action;
		return { ...state, overlayPanel: payload };
	}

	case 'SUPPORT_CHAT_TOGGLE': {
		const { payload } = action;
		const status = typeof payload !== 'undefined' ? payload : !state.supportChatOpen;
		return { ...state, supportChatOpen: status };
	}

	// == Header
	case 'HEADER_PROFILE_CNT_REFRESH_INC': { // header profile submenu counters - trigger to refresh on any change (item/filter saved/subsribed)
		return { ...state, profileCntRefreshCnt: state.profileCntRefreshCnt + 1 };
	}

	case 'SET_HEADER_NOTIFICATION': {
		const { payload } = action;
		return { ...state, headerNotitication: payload };
	}

	// == Notifications (unread, header)
	case 'SET_NOTIFICATIONS_UNREAD': {
		const { payload } = action;
		return { ...state, notificationsUnreadList: payload };
	}

	case 'SET_NOTIFICATIONS_ISREAD': {
		return { ...state, notificationIsRead: true };
	}

	case 'TOGGLE_NOTIFICATIONS': {
		return { ...state, notificationIsVisible: !state.notificationIsVisible };
	}

	default:
		return state;
	}
};

export default reducer;
