const request = {
	validation: {
		organisation_nm: {
			required: true,
			validator: null
		},

		organisation_address: {
			required: true,
			validator: null
		},

		organisation_postal_code: {
			required: true,
			validator: null
		},

		organisation_city_nm: {
			required: true,
			validator: null
		},

		organisation_tax_no: {
			required: true,
			validator: null
		},

		organisation_email: {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				error: 'Invalid mail'
			}
		},

		organisation_invoice_via_mail: {
			required: false,
			validator: null
		},

		organisation_invoice_via_post: {
			required: false,
			validator: null
		}
	},

	gql: {
		schema: null
	}
};

export default request;
