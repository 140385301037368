import { gql } from 'graphql-request';

// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>application_mutate_status'
	},

	form: {},

	gql: {
		schema: gql`
			mutation AuctionApplicationMutateStatus(
				$action: String,
				$auction_application_id: ID,
				$status_id: ID,
				$note: String
			) {
				request(
					action: $action,
					auction_application_id: $auction_application_id,
					status_id: $status_id,
					note: $note
				) {
					auction_application_id
					status_id
					status_nm
					status_style
					note
				}
			}
		`
	}
};

export default request;
