import { gql } from 'graphql-request';

// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>auction_message'
	},

	form: {
		fieldProps: {
			real_estate_id: {
				required: true,
			},
			message: {
				required: true,
			}			
		}
	},

	gql: {
		schema: gql`
			query AuctionMessage(
				$real_estate_id: ID,
				$message: String
			) {
				request(
					real_estate_id: $real_estate_id,
					message: $message
				) {
					status
				}
			}
		`
	}
};

export default request;
