import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>mutate_status'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionMutateStatus(
				$real_estate_id: Int,
				$auction_status_id: ID
			) {
				request(
					real_estate_id: $real_estate_id,
					auction_status_id: $auction_status_id
				) {
					real_estate_id
					auction_status_id
				}
			}
		`
	}
};

export default request;
