import { gql } from 'graphql-request';

// New - use react-query
const request = {
	request: {
		name: 'auction>applications'
	},

	form: {},

	gql: {
		schema: gql`
			query AuctionApplications(
				$status_id: ID,
				$real_estate_id: ID,
				$group_by: [String]
			) {
				request(
					status_id: $status_id,
					real_estate_id: $real_estate_id,
					group_by: $group_by
				) {
					auction_application_id
					auction_application_dtm
					status_id
					status_nm
					status_style
					givenname
					surname
					real_estate_id
					real_estate_nm
					uri_segment
					specifications
					location
					price
					auction_start_dtm
					image_filepath_url
					note
					created_dtm
					is_winner
				}
			}
		`
	}
};

export default request;
