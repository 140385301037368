export const timeConverter = (timeStamp) => {
	const a = new Date(timeStamp * 1000);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return `${a.getDate()} ${months[a.getMonth()]} ${a.getFullYear()} ${a.getHours()}:${a.getMinutes()}:${a.getSeconds()}`;
};

export const parseJwt = (token = null, fieldName = null) => {
	if (token) {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		const jwtParsed = JSON.parse(window.atob(base64));

		if (fieldName !== null) {
			return jwtParsed[fieldName] || null;
		}

		return jwtParsed;
	}

	return null;
};

// Check, if localy saved token is still valid (not expired). For more detailed checking, ask server, to check, if token is in db as valid!!
export const isTokenValid = (token = null) => {
	const jwt = parseJwt(token);

	if (!jwt) {
		return false;
	}

	if (Date.now() >= jwt.exp * 1000) {
		return false;
	}

	return true;
};
