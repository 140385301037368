import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'coderegister>loader'
	},

	gql: {
		schema: gql`
			query CoderegisterLoader(
			  $name: String!
			) {
			  request(
			    name: $name,
			  ) {
			    loader
			  }
			}
		`
	}
};

export default request;
