import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/siid';

const SiidContext = createContext();
export default SiidContext;

export function SiidProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		// General
		siid: null,
		siidNm: null,

		// Auth (last status)
		lastAuthStatus: null, // no status set yet!

		// Header
		header: {
			nav: [],
			navProfile: []
		},

		// Footer
		footer: {
			navTop: [],
			navBottom: []
		},

		// Social links
		socialLinks: [],
	});

	return (<SiidContext.Provider value={{ data, dispatch }}>{children}</SiidContext.Provider>);
}

SiidProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(SiidContext);
