import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'contact>item'
	},

	gql: {
		schema: gql`
			query ContactSingle(
				$contact_id: String
			) {
				request(
					contact_id: $contact_id
				) {
					contact_id
					contact_type_id
					organisation
					organisation_nm
					organisation_taxable
					organisation_tax_no
					organisation_taxable
					givenname
					surname
					email
					email_organisation
					telephone
					telephone_organisation
					postal_address
					postal_organisation_address
					postal_city_nm
					postal_organisation_city_nm
					postal_postal_code
					postal_organisation_postal_code
					postal_country_iso3
					postal_country_nm
					postal_organisation_country_iso3
					postal_organisation_country_nm
					http_organisation
					agreement_gdpr
          agreement_terms
          agreement_contact
				}
			}
		`
	}
};

export default request;
