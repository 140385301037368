import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'webshop>invoice_list'
	},

	gql: {
		schema: gql`
			query InvoiceList {
			  request {
			    invoice_ref
					invoice_dtm
					filepath_url
			  }
			}
		`
	}
};

export default request;
