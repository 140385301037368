
const lng = {
	upload_error_min_size: 'Datoteka je premajhna.',
	upload_error_max_size: 'Datoteka je prevelika.',
	upload_error_mime_type: 'Napačen format daoteke, dovoljeni formati: %s',
	validation_error_required: 'Obvezno polje.',
	validation_error_password_number: 'Geslo mora vsebovati vsaj eno število!',
	validation_error_password_letter: 'Geslo mora vsebovati vsaj eno črko!',
	validation_error_password_match: 'Gesli se ne ujemata.',
	validation_error_date: 'Napačen format datuma (Pravilen format: %s).',
	validation_error_email: 'Napačen format e-maila.',
	validation_error_numeric: 'Vrednost mora biti numerična.',
	validation_error_max_length: 'Max. število znakov je %s.',
	validation_error_min_length: 'Min. število znakov je %s.',
	validation_error_length: 'Dovoljena dolžina znakov je natanko %s.',
	validation_error_username_exist: 'Uporabniško ime ni na voljo.',
	validation_error_unique: 'Vnos že obstaja.',
	validation_error_email_exist: 'E-mail ne obstaja.',
	validation_recovery_hash_not_valid: 'Unikatna koda za ponastavitev gesla ni veljavna.',
	validation_confirmation_hash_not_valid: 'Potrditvena koda ni veljavna.',
	validation_user_not_found: 'Uporabnik ne obstaja.',
	validation_error_ctype_alpha: 'Dovoljene so samo črke.',
	validation_error_ctype_alnum: 'Dovoljene so samo črke in števila.',
	validation_error_incorrect_password: 'Nepravilno geslo',
	login_error_account_deleted: 'Račun je pobrisan.',
	login_error_account_not_active: 'Račun ni aktiven.',
	login_error_account_not_confirmed: 'Račun ni potrjen.',
	login_error_username_not_found: 'Uporabniški račun še ne obstaja. Potrebna je registracija.',
	login_error_with_old_password: 'Poskušate se prijaviti s starim geslom. Spremeljeno: %s',
	login_error_wrong_password: 'Napačno geslo.',
	login_error_wrong_password_oauth: 'Registrirani ste že s pomočjo: %s (%s). Poskusite se prijaviti s to storitvijo.',
	register_error_email_unique_oauth: 'Po neuspešnem poskusu prijave ({{oauth_provider}}) smo vas želeli registrirati, vendar račun z e-mail naslovom {{email}} že obstaja. Poskusite se prijaviti z drugo storitvijo ali si ponastavite geslo.',
	err_user_not_found: 'Uporabnik ne obstaja.',
	label_tax_number_prefix_taxable: 'ID za DDV:',
	label_tax_number_prefix_nottaxable: 'Davčna št.:',
	label_tax_number_prefix_abroad: 'VAT ID:',
	navPricelist: 'Cenik',
	navAbout: 'Mojedražbe PRO',
	navRegister: 'Registriraj se',
	navContact: 'Kontakt',
	navAdvertisment: 'Oglaševanje',
	navSupport: 'Pomoč in podpora',
	navTerms: 'Pogoji uporabe',
	navPrivacy: 'Zasebnost',
	navNotifications: 'Obvestila',
	navLogout: 'Odjava',
	navLogin: 'Vpiši se',
	navHelp: 'Pomoč',
	navUserAccount: 'Nastavitve računa',
	navListView: 'Vse dražbe',
	navSaved: 'Shranjeno',
	navListSavedItems: 'Shranjeni oglasi',
	navListSavedFilters: 'Shranjeni filtri',
	navListInsertedItems: 'Moji oglasi',
	navItemMatch: 'Oglasi zame',
	navAddAuction: 'Objavi oglas',
	navMyAccount: 'Moj račun',
	navHome: 'Domov',
	navProfile: 'Profil',
	navSearch: 'Išči',
	heroTitle: 'Dražbe nepremičnin za vsakogar',
	heroDescription: 'Najbolj kakovostni podatki za brezskrbno sodelovanje na dražbah nepremičnin.',
	heroSeeAllAuctions: 'Oglejte si vseh {cnt} dražb',
	exposedHiwTitle1: 'Intuitivno iskanje in obveščanje',
	exposedHiwText1: 'Hitro in enostavno filtrirajte dražbe ter bodite obveščeni o ponudbah, ki vas resnično zanimajo.',
	exposedHiwTitle2: 'Kakovostni in pregledni podatki',
	exposedHiwText2: 'Preverite vse podrobnosti o nepremičnini in o sodelovanju na dražbi.',
	exposedHiwTitle3: 'Poglobljene analize vrednosti',
	exposedHiwText3: 'Preverite realne cene nepremičnin na dražbi in ocenite smotrnost nakupa.',
	textBoxCtaTitle1: 'Preizkusite',
	textBoxCtaText1: 'Odklenite polno funkcionalnost portala in dostopajte do najkakovostnejših podatkov o dražbah nepremičnin.',
	itemListTitle3: 'Nove dražbe',
	itemListTitle1: 'Dražbe pred iztekom',
	itemListTitle2: 'Potekle dražbe',
	itemListTitle4: 'Dražbe v karanteni',
	btnBecomePro: 'Postani PRO uporabnik',
	btnUnlockPro: 'Za ogled odkleni',
	btnPricelist: 'Preveri cenik',
	btnBuyPro: 'Kupi PRO dostop',
	btnReadMore: 'Preveri več',
	btnReadMoreShort: 'Več',
	btnShow: 'PRIKAŽI',
	btnFilters: 'Filtri',
	btnSave: 'Shrani',
	btnSaveFilters: 'Shrani filtre',
	btnShowItems: 'Pokaži oglase',
	btnClean: 'Počisti',
	btnCleanAll: 'Počisti vse',
	btnGallery: 'Galerija',
	btnTloris: 'Tloris',
	btnStreetView: 'Ulični pogled',
	btnMap: 'Zemljevid',
	btnShare: 'Deli',
	btnPrint: 'Natisni',
	btnAuctionParticipate: 'Sodeluj v dražbi',
	btnAuctionNotifyMe: 'Obveščaj me',
	btnShowMore: 'Pokaži več',
	btnShowLess: 'Pokaži manj',
	btnShowAll: 'Pokaži vse',
	btnCheckNow: 'Preveri sedaj',
	btnCheckMoreMk: 'Preveri več na Mojikvadrati.com',
	btnGoToEvaluationTool: 'Odpri bolj podrobno',
	btnRegister: 'Registriraj se',
	btnLogin: 'Vpiši se',
	btnGetPassword: 'Pošlji',
	btnLoginFacebook: 'Prijavi se s Facebook',
	btnLoginGoogle: 'Prijavi se z Google',
	btnRegisterManual: 'Registriraj se z e-mailom',
	btnSaveChanged: 'Shrani spremembe',
	btnChangeEmail: 'Spremenite email naslov.',
	btnChangePhone: 'Spremenite tel. št.',
	btnDelete: 'Izbriši',
	btnDetailed: 'Podrobnosti',
	btnShowAuctions: 'Prikaži dražbe',
	btnNotificationsSettings: 'Nastavitve obvestil',
	btnCancel: 'Prekliči',
	btnNext: 'Naprej',
	btnBack: 'Nazaj',
	btnUploadFromPc: 'Naloži iz računalnika',
	btnAddLocation: 'Dodaj lokacijo',
	btnBySteps: 'Po korakih',
	btnChange: 'Spremeni',
	btnSetFilter: 'Nastavi filter',
	btnBrowserItems: 'Prebrskajte vse dražbe',
	btnFinish: 'Zaključi',
	btnPay: 'Plačaj',
	btnClose: 'Zapri',
	btnGoToDocumentation: 'Poglej dokumentacijo',
	btnStart: 'Začni',
	btnInfoTour: 'Info za ogled',
	btnRefresh: 'Osveži',
	btnMoreAboutPro: 'Preveri več o PRO',
	btnRestoreNow: 'Obnovite paket.',
	btnSelectProduct: 'Izberite paket',
	btnGetTrial: 'Preizkusite 14 dni brezplačno!',
	btnRecurringCancel: 'Prekliči naročnino',
	btnOpen: 'Odpri',
	labelNotificationPro: 'Obvestila o dražbi so zaklenjena. Postanite PRO uporabnik.',
	labelAt: 'ob',
	labelGursValue: 'Vrednost po GURS',
	labelGursValueText: 'Vrednost nepremičnine po GURS',
	labelSellingShare: 'Delež, ki se prodaja',
	labelBedroomCnt: 'Št. spalnic',
	labelBuiltLong: 'Leto izgradnje',
	labelAdaptationLong: 'Leto adaptacije',
	labelAdditional: 'Dodatno',
	labelAdditionalDesc: 'Izberite dodatne možnosti, da bolje opišete nepremičnio.',
	labelHeating: 'Ogrevanje',
	labelHeatingDesc: 'Izberite načine ogrevanja.',
	labelConnector: 'Priključki',
	labelConnectorDesc: 'Izberite priključke.',
	labelRoom: 'Prostori',
	labelRoomDesc: 'Izberite prostore.',
	labelLuxury: 'Prestiž',
	labelLuxuryDesc: 'Izberite prestižne značilnosti nepremičnine',
	labelNearBy: 'V bližini',
	labelNearByDesc: 'Dodajte relevantne točke in lokacije v bližini nepremičnine in njihovo oddaljenost od nepremičnine.',
	labelRooms: 'Prostori',
	labelPriceM2: 'Cena/m2',
	labelAuctionPriceM2: 'Izklicna cena na m2',
	labelValuationValue: 'Ocena cenilca',
	labelValuationDtm: 'Datum ocene',
	labelEvaluationDtm: 'Datum cenitve',
	labelValuesComparison: 'Primerjava ocen vrednosti',
	labelValuesComparisonText: 'Primerjava cen in ocen vrednosti na trgu',
	labelAppraiserValue: 'Ocena vrednosti cenilca',
	labelAppraiserValueText: 'Ocena vrednosti nepremičnine, ki jo na osnovi predhodnega ogleda poda sodni cenilec.',
	labelMarketValue: 'Ocena vrednosti na trgu',
	labelMarketValueText: 'Ocena naprednega sistema umetne inteligence glede na podobne nepremičnine v okolici.',
	labelMarketRealizedValue: 'Realizirane cene podobnih prodanih nepremičnin v okolici - na trgu',
	labelMarketRealizedValueText: 'Prikazane so prodane nepremičnine v radiju 1km od izbrane lokacije in razvrščene glede na oddaljenost od nepremičnine na dražbi. Vir: GURS.',
	labelAuctionRealizedValue: 'Realizirane cene podobnih prodanih nepremičnin v okolici - na dražbah',
	labelAuctionRealizedValueText: 'Prikazane so prodane nepremičnine v radiju 1km od izbrane lokacije in razvrščene glede na oddaljenost od nepremičnine na dražbi. Vir: GURS.',
	labelPrevActivityValue: 'Pretekle aktivnosti s to nepremičnino',
	labelPrevActivityValueText: 'Na voljo so podatki od leta 2014 naprej.',
	labelMarketCurrentAdvertisedValue: 'Trenutne oglaševane cene podobnih nepremičnin na trgu',
	labelMarketCurrentAdvertisedValueText: 'Prikazane so nepremičnine na trgu, ki so podobne nepremičnini na dražbi.',
	labelMarketCurrentAdvertisedGridValue: 'Oglasi podobnih nepremičnin na trgu',
	labelMarketCurrentAdvertisedGridValueText: 'Prikazane so nepremičnine na trgu, ki so podobne nepremičnini na dražbi.',
	labelCheckRelatedItems: 'Preverite več sorodnih nepremičnin',
	labelCourtOrder: 'Odredba sodišča',
	labelValuationReport: 'Cenilno poročilo - ocena vrednosti sodnega cenilca',
	labelGursDataPrint: 'Izpisek podatkov iz baze GURS',
	labelLandRegistryStatus: 'Stanje v Zemljiški knjigi',
	labelTerms: 'Pogoji uporabe',
	labelDownloadReport: 'Celotno poročilo',
	labelBusinessDateRange: 'Obdobje poslov',
	labelAvarageRealizedPrice: 'Povprečna realizirana cena',
	labelAvarageRealizedPriceM2: 'Povprečna realizirana cena na m2',
	labelAvaragePrice: 'Povprečna cena',
	labelAvaragePriceM2: 'Povprečna cena na m2',
	labelPrice: 'Cena',
	labelSize: 'Velikost',
	labelGursId: 'GURS ID',
	labelRealizedSoldPrice: 'Realizirana prodajna cena',
	labelTransactionDate: 'Datum transakcije',
	labelAuctionStart: 'Začetek dražbe',
	labelGivenname: 'Ime',
	labelSurname: 'Priimek',
	labelName: 'Ime in priimek',
	labelEmail: 'Email',
	labelUsername: 'Uporabniško ime',
	labelPassword: 'Geslo',
	labelPasswordRepeat: 'Ponovite geslo',
	labelRememberMe: 'Zapomni si me',
	labelForgotPassword: 'Pozabljeno geslo?',
	labelRegister: 'Še nimaš računa? <strong>Registriraj se.</strong>',
	labelLogin: 'Že imaš račun? <strong>Vpiši se</strong>',
	labelGdprAgreement: 'Z vpisom se strinjate s <a href="/o-nas/splosni-pogoji-uporabe" target="_blank">Pogoji uporabe in politiko zasebnosti</a>',
	labelRegisterAgreement: 'Z registracijo na Mojedražbe.com začne teči 14 dnevni rok, kjer boste imeli brezplačno na voljo polno funkcionalnost portala. Po poteku roka bodo vse napredne funkcije zaklenjene. Za ponovno aktivacijo bo potrebno izvesti nakup enega od paketov.',
	labelSettings: 'Nastavitve',
	labelAccount: 'Uporabniški račun',
	labelAccountProfile: 'Uporabniško ime in geslo',
	labelAccountNotifications: 'Obvestila',
	labelAccountPayments: 'Plačila',
	labelAccountSaved: 'Shranjeno',
	labelPostalCode: 'Poštna št.',
	labelAdministativeUnit: 'Upravna enota',
	labelCity: 'Občina',
	labelDistrict: 'Kraj/četrt',
	labelPhone: 'Telefon',
	labelWebsite: 'Spletna stran',
	labelIsOrganisation: 'Podatki podjetja',
	labelOrganisation: 'Naziv podjetja',
	labelTaxNo: 'Davčna št.',
	labelOrganisationTaxable: 'Davčni zavezanec',
	labelPasswordNew: 'Novo geslo',
	labelPasswordNewRepeat: 'Ponovno vnesite novo geslo',
	labelPasswordOld: 'Trenutno geslo',
	labelNotifyEnabled: 'Obvestila vključena',
	labelNotifyOptEmail: 'Email obvestila',
	labelNotifyOptSms: 'SMS obvestila',
	labelNotifyOptNotification: 'Spletna obvestila',
	labelNotifyNewDeals: 'Obveščaj me o ponudbah',
	labelNotifyAuctionBeforeExpiration: 'Obvestila o dražbah pred potekom',
	labelNotifyAuctionChanges: 'Obvestila o ujemanju s kriteriji iskanja',
	labelNotifyAuctionMatched: 'Obvestila o spremembah na dražbi',
	labelNotifySimilarAuction: 'O morebitni drugi dražbi (50% izklicna cena)',
	labelNotifyAuctionResult: 'Za koliko se bo ta nepremičnina podala',
	labelNotifyAuctionReminder7days: 'Obvesti me 7 dni pred to dražbo',
	labelInvoiceSendViaMail: 'Račun mi pošljite po email-u',
	labelInvoiceSendViaPost: 'Račun mi pošljite na zgornji naslov',
	labelPropertyTypeLong: 'Vrsta nepremičnine',
	labelPropertySubtypeLong: 'Tip nepremičnine',
	labelEnergyCertLong: 'Energetska izkaznica',
	labelAuctionNotifications: 'Obvestila o dražbi',
	labelPaymentNotifications: 'Izbor načina plačila',
	labelRegion: 'Regija',
	labelDescriptionShort: 'Kratek opis',
	labelDescriptionShortDesc: 'Izpostavite glavne lastnosti nepremičnine',
	labelDescription: 'Podroben opis',
	labelDescriptionDesc: 'Podrobno opišite nepremičnino in njene lastnosti.',
	labelPhotos: 'Slike in fotografije',
	labelPhotosDesc: 'Dodajte slike, fotografije, tlorise itn.',
	labelFiles: 'Naloži datoteke',
	labelFilesDesc: 'Odredbe sodišč, poročila cenilcev, stanje v zemljiški knjigi, ...',
	labelDragAndDropUpload: 'Primi in povleci slike v okvir',
	labelOr: 'ali',
	labelPriceStart: 'Izklicna cena',
	labelTimeExpire: 'Do pologa varščine',
	labelExpired: 'Potekla',
	labelCanceled: 'Preklicana',
	labelProAccountExpired: 'Vaša PRO naročnina je potekla.',
	labelTrialAccountExpired: 'Poskusno obdobje je poteklo.',
	labelTrialAccountCountdown: 'Preizkusno obdobje poteče čez {{countdown}}.',
	labelGetTrial: 'Preizkusite PRO - 14 dni brezplačno!',
	labelDate: 'Datum',
	labelDays: 'dni',
	labelStart: 'Začetek',
	labelLocation: 'Lokacija',
	labelLocationAuction: 'Lokacija dražbe',
	labelVaranty: 'Varščina',
	labelVarantyDtm: 'Varščina do',
	labelContact: 'Kontakt',
	labelContactV2: 'Kontakt za ogled',
	labelProCtaText: 'Dostopajte do vseh informacij takoj!',
	labelAuctionAdditional: 'Več o dražbi',
	labelContactData: 'Kontaktni podatki',
	labelContactDataDesc: 'Kontaktni podatki bodo prikazani v ponudbi.',
	labelContactPhone: 'Kontaktna številka',
	labelDropHereAndUpload: 'Spusti in naloži ;)',
	labelAuctionSequence: 'Tip prodaje',
	labelAuctionSequenceShort: 'Dražba',
	labelAuctionPrimaryAgentNm: 'Ogled vodi',
	labelStatus: 'Status',
	labelAuctionOfferType: 'Tip ponudbe',
	labelAuctionOfferTypeShort: 'Tip',
	labelSequenceNo: '#',
	labelCodeid: 'Šifra oglasa',
	labelGeodeticid: 'GURS ID',
	labelGeodeticidDetailUrl: 'eProstor podrobno',
	labelGeodeticidMapUrl: 'eProstor zemljevid',
	labelStorey: 'Etažnost',
	labeShareSale: 'Delež na dražbi',
	labelSecurityDepositDueDate: 'Vplačati do',
	labelAuctionPrice: 'Izklicna cena',
	labelAuctionCountdown: 'Do izteka še',
	labelCheckingProfileAccess: 'Preverjam dostop ...',
	labelFilters: 'Filtri',
	labelRealEstates: 'nepremičnin',
	labelSavedActiveAuctions: 'trenutno aktivnih dražb',
	labelNoResultNoData: 'Podatki niso na voljo.',
	labelNoResultsFiltersTitle: 'Vaš seznam oglasov je prazen.',
	labelNoResultsFilters: 'Shranite priljubljene oglase in nastavite obveščanje.',
	labelNoResultsAuctionsTitle: 'Seznam oglasov je prazen.',
	labelNoResultsAuctions: 'Nobena aktualna dražba ne ustreza izbranim kreiterijem.',
	labelNoResultsPaymentsHistory: 'Zgodovina plačil je prazna.',
	labelNoResultsSavedItemsTitle: 'Vaš seznam oglasov je prazen.',
	labelNoResultsSavedItems: 'Shranite priljubljen oglas oz. se prijavite na obveščanje.',
	labelNoResultsMyItemsTitle: 'Vaš seznam oglasov je prazen.',
	labelNoResultsMyItems: 'Objavite nov oglas.',
	labelNoResultsLinkedAuctions: 'Za to dražbo ni povezanih dražb.',
	labelNoResultsNoAdditionalItems: 'Seznam dodatnih nepremičnin je prazen.',
	label_from: 'od',
	label_to: 'to',
	labelExpireLogin: 'Prijava poteče',
	labelExpirePro: 'PRO poteče',
	labelOneTimePayment: 'Enkratno plačilo',
	labelAndMore: 'in več ...',
	labelNo: 'Ne',
	labelYes: 'Da',
	labelAreYouSure: 'Ste prepričani?',
	labelSafeWebPayment: 'Varno spletno plačilo',
	labelSaved: 'Shranjeno',
	labelUser: 'Uporabnik',
	labelCalloutProTitle: 'Odklenite PRO',
	labelCalloutProText: 'Odklenite polno funkcionalnost in dostopajte do najkakovostnejših podatkov za sodelovanje na dražbah nepremičnin.',
	labelValuesComparisonPriceM2Disclaimer: '* uporabljena površina za izračun izklicne cene/m2 in ocene cenilca/m2',
	labelPromptAuctionFilterDeleteText: 'S potrditvijo boste filter izbrisali. Hkrati se boste tudi odjavili od prejemanja obvestil za izbrani filter.',
	labelPromptAuctionDeleteText: 'S potrditvijo boste oglas izbrisali.',
	labelPromptRelationsText: 'S potrditvijo boste oglas odstranili iz seznama shranjenih in se hkrati odjavili od vseh obveščanj v zvezi s to dražbo.',
	labelPromptRecurringCancelText: 'Ste prepričani, da želite prekiniti nadaljna plačila za samodejno obnavljanje vašega PRO dostopa?',
	labelEtnType: 'Tip posla',
	labelEtnContractDate: 'Datum posla',
	labelEtnContractPrice: 'Realizirana cena',
	labelEtnContractLocation: 'Lokacija',
	labelEtnSizeAll: 'Skupna površina',
	labelEtnParts: 'Deli nepremičnine',
	labelEtnSize: 'Površina',
	labelEtnSoldShare: 'Prodan delež',
	labelEtnRoomCnt: 'Št. sob',
	labelEtnOrientation: 'Lega v stavbi',
	labelCheckoutTitle: 'Blagajna',
	labelCheckingPayment: 'Preverjam plačilo...',
	labelPaymentSuccess: 'Plačilo uspešno izvedeno.',
	labelPaymentError: 'Težava pri plačilu oz. ni plačan celoten znesek.',
	labelPaymentProviderpaypal_rest: 'PayPal',
	labelPaymentProviderbankart: 'Kreditna kartica',
	labelPaymentRedirectToPayment: 'Preusmerjam na plačilo',
	labelPaymentProcess: 'Payment is being processed. please refresh in a few minutes.',
	labelPreparingInvoice: 'Pripravljam račun...',
	labelInvoiceReady: 'Vaš račun je uspešno pripravljen in poslan na vaš e-mail naslov.',
	labelInvoiceError: 'Težava pri pripravi računa. Prosimo, kontaktirajte administratorja.',
	labelPurchaseThanks: 'Hvala za nakup.',
	labelRedirecting: 'Preusmerjam...',
	label_soon: 'Kmalu',
	placeholderDescription: 'Opis ...',
	placeholderDistance: 'Vnesi razdaljo',
	placeholderSelect: 'Izberi',
	placeholderSelectAllTypes: '- Vsi tipi -',
	placeholderSelectAllRegion: '- Vse regije -',
	placeholderSecurityDeposit: 'Vnesite višino varščine',
	placeholderAddress: 'Na primer: Angele vode 40',
	labelFormDescPersonalData: 'Osebni podatki',
	labelFormDescPersonalDataText: 'Ti podatki niso vidni in jih ne bomo delili z nikomer. Uporabili jih bomo le za kontakt z vami.',
	labelFormDescOrganisationData: 'Podatki o podjetju',
	labelFormDescOrganisationDataText: 'Ti podatki niso vidni in jih ne bomo delili z nikomer. Uporabili jih bomo le za kontakt z vami.',
	labelFormDescUsername: 'Uporabniško ime',
	labelFormDescUsernameText: 'Uporabniško ime mora biti unikatno.',
	labelFormDescPasswordChange: 'Spremeni geslo',
	labelFormDescPasswordChangeText: 'Ponastavite svoje geslo tako, da najprej vpišete trenutno geslo in nato novo.',
	labelFormDescNotifications: 'Obvestila',
	labelFormDescNotificationsText: 'Izberite, kako želite prejemati obvestila o dražbah, ki jim sledite.',
	labelFormDescPaymentAccount: 'Naslov za račun',
	labelFormDescPaymentAccountText: 'Na računu bodo prikazani podatki, kot ste jih navedli v uporabniškem računu. Lahko jih spremenite.',
	labelFormDescPaymentHistory: 'Plačila',
	labelFormDescPaymentHistoryText: 'Imejte kontrolo nad plačili in prejetimi računi.',
	labelPaymentLastPayment: 'Plačilo',
	labelPaymentInvoiceList: 'Prejeti računi',
	labelSecurityDeposit: 'Varščina',
	labelRecurringPaymentProduct: 'Izbran paket',
	labelRecurringPaymentStatus: 'Status plačila',
	labelRecurringPaymentText: 'Naročnina se samodejno podaljšuje po izteku zakupljenega obdobja. V kolikor želite naročnino preklicati, izberite možnost spodaj.',
	labelRecurringPaymentStatusACTIVE: 'Aktivno',
	labelRecurringPaymentStatusACTIVE_PENDING: 'Aktivacija v teku ...',
	labelRecurringPaymentStatusCANCELLED: 'Preklicano',
	labelRecurringPaymentStatusCANCEL_PENDING: 'Preklic v teku ...',
	labelRecurringPaymentStatusPAUSED: 'Na pauzi.',
	labelPageNotFoundTitle: 'Stran ne obstaja.',
	validationRequired: 'Obvezno polje',
	validationGivenname: 'Neveljavno ime',
	validationSurname: 'Neveljaven priimek',
	validationEmail: 'Neveljaven e-mail',
	validationNumeric: 'Vrednost mora biti numerična.',
	validationMaxLength: 'Max. število znakov je: ',
	validationMinLength: 'Min. število znakov je: ',
	validationLength: 'Dovoljena dolžina znakov je natanko: ',
	authRegisterTitle: 'Registriraj se',
	authRegisterText: 'Ustvarite PRO račun in preizkusite polno funkcionalnost - 14 dni brezplačno!',
	authRegisterConfirmationTitle: 'Potrdite svoj e-mail naslov.',
	authRegisterConfirmationText: 'Na e-mail smo vam poslali potrditveno povezavo. Po aktivaciji se boste lahko prijavili.',
	authLoginTitle: 'Vpiši se',
	authLoginText: 'Odprite dostop do vseh podatkov dražb.',
	authForgotPasswordTitle: 'Pozabljeno geslo',
	authForgotPasswordText: 'Vpišite svoj e-mail in prejeli boste link za resetiranje gesla',
	authForgotPasswordMailSentText: 'Na vpisan e-mail smo vam poslali povezavo za resetiranje gesla. Preverite mail.',
	authForgotPasswordResetTitle: 'Ponastavite geslo',
	authForgotPasswordResetText: 'Vpišite novo geslo, ga potrdite in se nato prijavite.',
	authConfirmationChecking: 'Preverjanje računa ...',
	authConfirmationConfirmed: 'Račun potrjen! Prijavljam in preusmerjam ...',
	authHeroTitle: 'Preizkusite PRO - <br /> 14 dni brezplačno!',
	authHeroText: 'Odklenite polno funkcionalnost portala Mojedražbe PRO in dostopajte do najkakovostnejših podatkov za sodelovanje na dražbah nepremičnin.',
	authHeroFeatures: '<li>✓ Pametno filtriranje</li><li>✓ Hiter pregled</li><li>✓ Sledenje dražbam</li><li>✓ Obvestila</li><li>✓ Analiza vrednosti</li><li>✓ Priprava na dražbo</li>',
	auctionStepTitle1: '1. Registrirajte se na portalu Mojedrazbe.com',
	auctionStepText1: 'S PRO dostopom pridobite dostop do vseh pomembnih informacij o dražbi, ki vas zanima.',
	auctionStepTitle2: '2. Odredba sodišča',
	auctionStepText2: 'Preverite odredbo sodišča za nepremičnino, ki je na dražbi.',
	auctionStepTitle3: '3. Preverite dokumentacijo o nepremičnini',
	auctionStepText3: 'Pozorno preglejte dokumentacijo in uporabite naša napredna orodja za analizo trga.',
	auctionStepTitle4: '4. Preverite podrobnosti sodelovanja na dražbi in možnosti ogleda nepremičnine',
	auctionStepText4: 'Preverite pogoje za sodelovanje na dražbi.',
	auctionStepTitle5: '5. Uredite financiranje',
	auctionStepText5: 'Zelo hitro se lahko zgodi, da na dražbi uspete, vendar je kupec nepremičnine na dražbi dolžan plačati kupnino v 30-tih dneh od uspešnega nakupa na dražbi.',
	auctionStepText51: 'Preverite: <br />● Način plačila (gotovina, kredit, nakazilo...) <br /> ● Višino varščine, <br /> ● Ali potrebujete dokazilo o sredstvih, <br /> ● Itn.',
	updaterTitle: 'Nova verzija portala Mojedrazbe.com!',
	updaterText: 'Prosimo, osvežite aplikacijo. Tako boste dobili v uporabo najnovejše posodobitve in funkcionalnosti, ki smo jih pripravili za vas.',
	calloutSellingShare: 'Opozorilo! Na tej dražbi se prodaja le {{auction_share_sale}} delež nepremičnine!',
	calloutAuctionBeforeStart: 'Pripravite se na dražbo in preverite seznam aktivnosti. Seznam opravljenih aktivnosti bo ostal shranjen v vašem profilu do vašega naslednjega obiska.',
	calloutCalculateCredit: 'Uporabite orodje <a href="https://izracun-kredita.si" target="_blank">izracun-kredita.si</a> kjer lahko pošljete povpraševanje za kredit večim bankam hkrati in prejmete njihove ponudbe.',
	calloutAuctionPopular: 'Popularno! Več kot 1200 ogledov',
	calloutAuctionExpireSoon: 'Kmalu poteče',
	calloutDone: 'Urejeno.',
	calloutNoChange: 'Nobena sprememba ni bila zaznana.',
	detailSectionGeneralTitle: 'Splošno',
	detailSectionDescriptionTitle: 'Opis',
	detailSectionAnalyseTitle: 'Analiza vrednosti',
	detailSectionChecklistTitle: 'Priprave na dražbo',
	detailSectionFaqTitle: 'Pogosta vprašanja',
	detailSectionDocumentsTitle: 'Dokumentacija',
	sectionTitleGeneral: 'Splošno o dražbi',
	sectionTitleItem: 'Splošno o nepremičnini',
	sectionTitleDescription: 'Opis',
	sectionTitleDocuments: 'Dokumentacija',
	sectionTitleAnalyse: 'Analiza vrednosti nepremičnine',
	sectionTitleChecklist: 'Priprave na dražbo',
	sectionTitleFaq: 'Pogosta vprašanja',
	sectionTextFaq: 'Za vas smo zbrali nekaj ključnih stvari, ki jih morate vedeti pri sodelovanju na dražbah. Za podrobne informacije o dražbah priporočamo, da preverite Zakon o izvršbi in zavarovanju, še posebej 13. poglavje: Izvršba na nepremičnine.',
	sectionTitleSiblings: 'Povezane dražbe',
	sectionTitleSiblingsLand: 'Nepremičnine na dražbi',
	filterSectionTitle1: 'Pred potekom',
	filterToggleTextOpt1: 'Oglasi, ki kmalu potečejo',
	filterSectionTitle2: 'Delež nepremičnine na dražbi',
	filterToggleTextOpt2: 'Samo 100% delež na dražbi',
	filterSectionTitle3: 'Tip prodaje',
	filterSectionTitle4: 'Izklicna cena',
	filterToggleTitleOpt3: 'Prva dražba',
	filterToggleTextOpt3: 'Izklicna cena dosega 70-100% ocenjene vrednosti.',
	filterToggleTitleOpt4: 'Druga dražba',
	filterToggleTextOpt4: 'Izklicna cena dosega 50% ocenjene vrednosti.',
	filterToggleTitleOpt5: 'Zavezujoče ponudbe',
	filterToggleTextOpt5: 'Prodano bo najboljšemu ponudniku nad izklicno ceno..',
	filterToggleTitleOpt6: 'Nezavezujoče ponudbe',
	filterToggleTextOpt6: 'Izklicna cena ni nujno podana, išče se interes za nakup.',
	pricelistTitle: 'Odklenite PRO funkcionalnost',
	pricelistText: 'Vse informacije, ki jih potrebujete za uspešno sodelovanje na dražbi.',
	formAuctionTitle: 'Objavi nov oglas',
	formAuctionEditTitle: 'Uredi oglas',
	formAuctionStepTitle1: '1. Tip dražbe',
	formAuctionStepTitle2: '2. Osnovni podatki',
	formAuctionStepTitle3: '3. Opis nepremičnine',
	formAuctionStepTitle4: '4. Podrobnosti dražbe',
	formAuctionStepTitle5: '5. Predogled',
	formAuctionbtnContinue2: 'Naprej na opis nepremičnine',
	formAuctionbtnContinue3: 'Naprej na podrobnosti dražbe',
	formAuctionbtnContinue4: 'Naprej na predogled',
	formAuctionbtnPublish: 'Objavi',
	formAuctionStepSubtitle1: 'Izberite tip dražbe',
	formAuctionStepDescription1: 'Izberite vrsto dražbe, ki jo želite objaviti. Dražijo se lahko tako prodaja nepremičnine, kot najemnine.',
	formAuctionStepSubtitle2: 'Vnesite podatke o nepremičnini',
	formAuctionStepDescription2: 'Vnesite osnovne podatke o nepremičnini na dražbi.',
	formAuctionStepSubtitle3: 'Opis nepremičnine',
	formAuctionStepDescription3: 'Čim bolje opišite vašo nepremičnino in dodajte čim več relevantnih informacij in fotografij.',
	formAuctionStepSubtitle4: 'Podrobnosti dražbe',
	formAuctionStepDescription4: 'Vnesite čim več podrobnosti o dražbi, ceni, izklicni ceni, …',
	footerCopyrights: '© 2021 100kvadratov d.o.o., Vse pravice pridržane',
	meta_home_title: 'Dražbe nepremičnin za vsakogar | Mojedrazbe.com',
	meta_home_description: 'Najbolj kakovostni podatki za brezskrbno sodelovanje na dražbah nepremičnin.',
	meta_home_keywords: 'dražbe nepremičnin, kakovostni podatki, transparentno, mojedražbe.com, mojedrazbe.com, edražbe',
	meta_auction_list_title: 'Aktivne dražbe nepremičnin | Mojedrazbe.com',
	meta_auction_list_description: 'Seznam aktivnih dražb nepremičnin v Sloveniji.',
	meta_auction_list_keywords: 'aktivne dražbe,mojedrazbe.com,drazba,nepremičnine',
	meta_landing_pro_title: 'Postani PRO uporabnik | Mojedrazbe.com',
	meta_landing_pro_description: 'PRO uporabnik ima dostop do naprednih funkcionalnosti za boljšo pripravo na dražbo',
	meta_landing_pro_keywords: 'mojedrazbe.com,pro uporabnik,dražbe nepremičnin,dražbe',
	meta_pricelist_title: 'Cenik | Mojedrazbe.com',
	meta_pricelist_description: 'Paketi s katerimi postanete PRO uporabnik',
	meta_pricelist_keywords: 'mojedrazbe.com,pro uporabnik,cenik,paketi,dostop',
	meta_auth_login_title: 'Prijava | Mojedrazbe.com',
	meta_auth_login_description: 'Odprite dostop do vseh podatkov dražb.',
	meta_auth_login_keywords: 'mojedrazbe.com, mojedražbe.com, prijava, dostop do podatkov',
	meta_auth_register_title: 'Registracija | Mojedrazbe.com',
	meta_auth_register_description: 'Ustvarite PRO račun in preizkusite polno funkcionalnost - 14 dni brezplačno!',
	meta_auth_register_keywords: 'mojedrazbe.com, dražbe nepremičnin, ustvari račun',
	meta_auth_forgot_password_title: 'Pozabljeno geslo | Mojedrazbe.com',
	meta_auth_forgot_password_description: 'Vpišite svoj e-mail in prejeli boste link za resetiranje gesla',
	meta_auth_forgot_password_keywords: 'pridobi geslo, dostop',
	meta_error_404_title: 'Stran ne obstaja | Mojedrazbe.com',
	meta_error_404_description: 'Želena stran več ni aktivna ali ne obstaja.',
	meta_error_404_keywords: 'mojedražbe.com, mojedrazbe.com, stran ne obstaja, dražbe nepremičnin',
	meta_contact_title: 'Kontakt | Mojedrazbe.com',
	meta_contact_description: 'Imate vprašanje, predlog ali težavo? Kontaktirajte nas. Z veseljem se odzovemo.',
	meta_contact_keywords: 'mojedrazbe.com,kontakt,100kvadratov',
	meta_terms_privacy_title: 'Splošni pogoji | Mojedrazbe.com',
	meta_terms_privacy_description: 'Splošni pogoji uporabe spletnega portala mojedrazbe.com',
	meta_terms_privacy_keywords: 'mojedrazbe.com,splošni pogoji uporabe,zasebnost,100kvadratov',
	meta_advertising_title: 'Oglaševanje | Mojedrazbe.com',
	meta_advertising_description: 'Bi želeli oglaševati na Mojedrazbe.com?',
	meta_advertising_keywords: 'mojedrazbe.com,oglaševanje,100kvadratov',
	page_pricelist_title_1: 'Kaj pridobite s PRO računom?',
	page_pricelist_box_1_title: 'Ocena vrednosti cenilca ali izvedenca',
	page_pricelist_box_1_text: 'Ocena vrednosti nepremičnine, ki jo na osnovi predhodnega ogleda poda sodni cenilec.',
	page_pricelist_box_2_title: 'Pregled cen nepremičnin v okolici',
	page_pricelist_box_2_text: 'Realizirane cene podobnih prodanih nepremičnin v okolici - na prostem trgu.',
	page_pricelist_box_3_title: 'Pregled realiziranih cen sorodnih dražb',
	page_pricelist_box_3_text: 'Realizirane cene podobnih prodanih nepremičnin v okolici - na dražbah.',
	page_pricelist_box_4_title: 'Pregled podobnih nepremičnin na trgu',
	page_pricelist_box_4_text: 'Trenutne oglaševane cene podobnih nepremičnin na trgu.',
	page_pricelist_box_5_title: 'Izračun izklicne cene',
	page_pricelist_box_5_text: 'Izračun in prikaz izklicne cene na m2.',
	page_pricelist_box_6_title: 'Izpisek podatkov iz baze GURS',
	page_pricelist_box_6_text: 'Izpis in prikaz podatkov iz Gurs-ove baze “Evidenca nepremičnin”.',
	page_pricelist_box_7_title: 'Priprava na dražbo',
	page_pricelist_box_7_text: 'Kontrolni seznam potrebnih aktivnosti za pripravo na dražbo.',
	page_pricelist_box_8_title: 'Cena prodane nepremičnine (TBA)',
	page_pricelist_box_8_text: 'Obvestilo o ceni, za koliko je bila določena nepremičnina na dražbi že prodana.',
	page_pricelist_box_9_title: 'Napredno filtriranje dražb',
	page_pricelist_box_9_text: '100% delež nepremičnine, prva dražba, druga dražba, tip nepremičnine...',
	page_pricelist_title_2: '...in vse, kar sodi zraven.',
	page_pricelist_box_10_title: 'Obveščanje o novih dražbah',
	page_pricelist_box_10_text: 'Email obvestila o poteku dražbe, ki vas zanima in ustreza vašim kriterijem iskanja.',
	page_pricelist_box_11_title: 'Obveščanje o aktualni dražbi',
	page_pricelist_box_11_text: 'Sledite dražbi, ki vas zanima in prejmite obvestila o poteku in spremembah.',
	page_pricelist_box_12_title: 'Shranjevanje filtrov',
	page_pricelist_box_12_text: 'Shranite kriterije vaših iskanj in jih uporabite kadarkoli.',
	page_pricelist_box_13_title: 'Lokacije dražbe',
	page_pricelist_box_13_text: 'Odklenite informacijo o lokaciji dražbe in jo dodajte med priljubljene.',
	page_pricelist_box_14_title: 'Čas začetka',
	page_pricelist_box_14_text: 'Odklenite informacijo o času začetka dražbe, ki jo opazujete.',
	page_pricelist_box_15_title: 'Višina varščine',
	page_pricelist_box_15_text: 'Preverite višino varščine s katero boste lahko sodelovali na dražbi.',
	page_pricelist_box_16_title: 'Kontaktni podatki',
	page_pricelist_box_16_text: 'Odklenite informacijo o kontaktnih podatkih prodajalcev.',
	page_pricelist_box_17_title: 'Dokumentacija',
	page_pricelist_box_17_text: 'Preverite vso potrebno dokumentacijo o nepremičninah na dražbi.',
	page_pricelist_box_18_title: '.. in še mnogo več.',
	page_pricelist_box_18_text: '',
	page_mdpro_hero_title: 'Uspeh na dražbi <br /> ni odvisen od sreče.',
	page_mdpro_hero_text: 'Ločite zrno od plevela - Odklenite polno funkcionalnost portala Mojedražbe PRO in dostopajte do najkakovostnejših podatkov za sodelovanje na dražbah nepremičnin.',
	page_mdpro_slide_1_title: 'Sodelovanje na dražbah nepremičnin še nikoli ni bilo <br /> tako enostavno, dostopno in pregledno.',
	page_mdpro_slide_2_title: 'Osredotočite se na kakovostne ponudbe.',
	page_mdpro_slide_2_subtitle: 'PAMETNO FILTRIRANJE',
	page_mdpro_slide_2_text: '<p>Želite kupiti 10% dvostanovanjske hiše? Seveda ne.</p><p>Hitro in enostavno filtrirajte dražbe po:</p><ul><li>&#10004; Deležu nepremičnine na dražbi</li><li>&#10004; Tipu prodaje</li><li>&#10004; Izklicni ceni</li><li> &#10004;Času poteka</li></ul>',
	page_mdpro_slide_3_title: 'Preverite vse informacije za uspešno in varno sodelovanje na dražbi.',
	page_mdpro_slide_3_subtitle: 'HITER PREGLED',
	page_mdpro_slide_3_text: '<p>Preverite izklicno ceno, čas do pologa varščine, datum in lokacijo dražbe, višino varščine in rok plačila ter kontakt za ogled.</p>',
	page_mdpro_slide_4_title: 'Shranite vaša iskanja in sledite dražbam, ki vas zanimajo.',
	page_mdpro_slide_4_subtitle: 'SLEDENJE DRAŽBAM',
	page_mdpro_slide_4_text: '<p>Če iščete stanovanje v Ljubljani, vas ne zanimajo hiše iz Domžal. Shranite zanimive dražbe, iskalne nize in filtre ter z njimi enostavno upravljajte in jih urejajte.</p>',
	page_mdpro_slide_5_title: 'Nastavite parametre obveščanja.',
	page_mdpro_slide_5_subtitle: 'OBVESTILA',
	page_mdpro_slide_5_text: '<p>Ste našli nepremičnino, ki bi vas zanimala šele pri 50% izklicne cene? Nastavite parametre obveščanja za posamezno dražbo in prejmite obvestila v vaš uporabniški račun in na email.</p>',
	page_mdpro_slide_6_title: 'Preverite realno vrednost nepremičnine.',
	page_mdpro_slide_6_subtitle: 'ANALIZA VREDNOSTI',
	page_mdpro_slide_6_text: '<p>Napredni algoritmi in orodja za oceno vrednosti nepremičnine poskrbijo za obogatene podatke, s katerimi lahko bolj natančno ocenite realno vrednost določene nepremičnine in smotrnost nakupa.</p>',
	page_mdpro_slide_7_title: 'Vodimo vas skozi proces priprave na dražbo',
	page_mdpro_slide_7_subtitle: 'PRIPRAVA NA DRAŽBO',
	page_mdpro_slide_7_text: '<p>Preverite vse korake, ki so potrebni za uspešno sodelovanje na dražbi ter se seznanite z odgovori na najpogosteje zastavljena vprašanja pri sodelovanju na dražbah.</p>',
	page_mdpro_slide_8_title: 'Vse se začne in konča pri ceni',
	page_mdpro_slide_8_subtitle: 'ANALIZA VREDNOSTI',
	page_mdpro_slide_8_text: 'Na dražbi sodelujete, ker lahko kupite nepremičnino po nižji ceni od njene tržne vrednosti. Ampak kakšna je realna vrednost?',
	page_mdpro_slide_8_box_1_title: 'Ocena vrednosti sodnega cenilca',
	page_mdpro_slide_8_box_1_text: 'Preverite, kako je določeno nepremičnino na dražbi ocenil sodni cenilec ali izvedenec.',
	page_mdpro_slide_8_box_2_title: 'Cene podobnih prodanih nepremičnin v okolici',
	page_mdpro_slide_8_box_2_text: 'Preverite, za koliko so se v preteklosti prodale podobne nepremičnine v neposredni okolici ter primerjajte prodaje na prostem trgu in na dražbah.',
	page_mdpro_slide_8_box_3_title: 'Cena nepremičnine v preteklosti',
	page_mdpro_slide_8_box_3_text: 'Preverite, ali se je dotična nepremičnina v preteklosti že prodala in za koliko. Na voljo so podatki od leta 2014 naprej.',
	cookies_consent_text: 'Na spletni strani uporabljamo piškotke, s pomočjo katerih izboljšujemo Vašo uporabniško izkušnjo in zagotavljamo kakovostne vsebine. Z nadaljnjo uporabo se strinjate z uporabo piškotkov.',
	cookies_consent_btn_ok: 'OK',
	labelPropertyType: 'Vrsta',
	labelPropertySubtype: 'Tip',
	labelEnergyCert: 'Energ. izaz.',
	labelLand: 'Parcela',
	labelBuilt: 'Zgrajeno',
	labelAdaptation: 'Adaptacija',
	labelInternalIdent: 'Šifra',
	labelFloor: 'Nadstropje',
	labelFloorCnt: 'Št. nadstropij',
	labelAddress: 'Naslov',
	labelAuctionCaseid: 'Opravilna št.'
};
export default lng;
