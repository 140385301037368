import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'notification>mutate'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation NotificationMutate(
				$log_notification_id: [Int],
				$isread: Int
			) {
				request(
					log_notification_id: $log_notification_id,
					isread: $isread
				) {
					response
				}
			}
		`
	}
};

export default request;
