import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/app';

const AppContext = createContext();
export default AppContext;

export function AppProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		// == Common
		properties: {}, //  ex. {version}
		overlayPanel: null, // ex. mobile nav`s, search filters, ...
		history: [],
		supportChatOpen: false,

		// == Header
		profileCntRefreshCnt: 0, // counter (number of saved items, filters, ...), which is inc+1 when item is (un)subscribed and added/removed from saved
		headerNotitication: null,

		// == Notifications (unread, header)
		notificationIsVisible: false,
		notificationIsRead: false,
		notificationsUnreadList: null,

		// == Paid access callout/notification (pro account, ...)
		paidAccessCallout: null
	});

	return (<AppContext.Provider value={{ data, dispatch }}>{children}</AppContext.Provider>);
}

AppProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(AppContext);
