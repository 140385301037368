import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'user>item'
	},

	gql: {
		schema: gql`
			query UserItem(
				$user_id: ID
			) {
				request(
					user_id: $user_id
				) {
					confirmed
					active
					user_id
					ulevel
					ulevel_expire_dtm
					ulevel_expire_dtm_tzid
					ulevel_expire_dtmz
					ounit
					username
					contact_id
					contact_nm
					primary_email
					ismoderator
					isadministrator
					oauth_provider
					oauth_id
					notification_email
					notification_sms
					notification_webpush
					notification_mobilepush
					product_codeid
				}
			}
		`
	}
};

export default request;
