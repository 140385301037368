const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_LOADER_DATA': {
		const { payload } = action;
		const { name, data } = payload;

		const loadedCnt = state.loadedCnt + 1;
		// return { ...state, [name]: data, loadedCnt, loaded: loadedCnt >= 3, [`is${name.charAt(0).toUpperCase() + name.slice(1)}Loaded`]: true };
		return { ...state, [name]: data, loadedCnt, loaded: loadedCnt >= 2, [`is${name.charAt(0).toUpperCase() + name.slice(1)}Loaded`]: true };
	}

	default:
		return state;
	}
};

export default reducer;
