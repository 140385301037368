// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>application_decline'
	},

	form: {
		fieldProps: {
			reason_opt1: {
				required: false
			},

			reason_opt2: {
				required: false
			},

			reason_opt3: {
				required: false
			},

			reason_opt4: {
				required: false
			},

			reason_opt5: {
				required: false
			},

			reason_opt6: {
				required: false
			},

			reason_text: {
				required: false
			}
		}
	},

	gql: {}
};

export default request;
