import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { GraphQLClient } from 'graphql-request';

import useConfig from './useConfig';

const useGQL = (name, requestName) => {
	const { clientConfig } = useConfig();

	const initGraphQLClient = (t = null, config = {}) => {
		const accessToken = (typeof window !== 'undefined' && window.localStorage.getItem('access_token')) ? window.localStorage.getItem('access_token') : null;

		// ANY CHANGE HERE, change also in: useGQL, helpers/gql SSRLoader (if necessary)
		return new GraphQLClient(`${clientConfig(process.env.NODE_ENV === 'development' ? 'API_URL_DEV' : 'API_URL')}/graphql?request=${name.replace('>', '--')}`, {
			headers: {
				'Request-Name': config.request ? (config.request.nameServer || config.request.name) : name,
				Authorization: accessToken ? `Bearer ${accessToken}` : null,
				Apikey: clientConfig('API_KEY'),
				Siid: clientConfig('SIID'),
				Possessid: (typeof window !== 'undefined' && window.localStorage.getItem('possessid')) ? window.localStorage.getItem('possessid') : null
			}
		});
	};

	const [requestConfig, addRequestConfig] = useState({});

	const parseGQLError = (err, props) => {
		const data = JSON.parse(JSON.stringify(err, undefined, 2));

		if (data && data.response) {
			const { errors } = data.response;

			// Handle potres API backend erros
			if (errors && errors.length > 0) {
				const { error, errorCode, message, errorData } = errors[0].potError;
				const { validation } = errorData;

				// Force logout from client!
				if (errorCode === 'not-authenticated') {
					console.log('FORCE LOGOUT - USER IS NOT LOGGED IN SERVER ANYMORE!');
				}

				return { request: {}, error, errorCode, message, validation };
			}

			// throw errors[0].potError ? errors[0].potError.message : errors[0].message;
			// return { request: {}, error: true, message: errors[0].potError ? errors[0].potError.message : errors[0].message, validation: null };
		}

		// console.log('ERROR GQL', props, data.response.errors[0].message, data.response.errors[0].potError.message);
		return { request: {}, error: true, message: 'Unknown server error', validation: null };
	};

	const { mutateAsync } = useMutation((props) => {
		const { gqlSchema, variables, config } = props;
		return request(gqlSchema, variables, config).catch(err => parseGQLError(err));
	}, {
		useErrorBoundary: true
	});

	const getConfig = (type) => {
		try {
			const replaceAll = (find, replace, str) => {
				const Regex = (find === '.') ? /\./g : new RegExp(find, 'g');
				return str.replace(Regex, replace);
			};

			if (!requestConfig[name]) {
				const config = require(`../../requests/${type}/${name.indexOf('>') !== -1 ? replaceAll('>', '/', name) : name}.js`);
				addRequestConfig({ ...requestConfig, [name]: config.default });
				return config.default;
			}

			return requestConfig[name];
		} catch (err) {
			console.log('Request config load error', type, name, err);
			return false;
		}
	};

	const request = (gqlSchema, variables, config) => {
		if (gqlSchema !== null) {
			const graphQLClient = initGraphQLClient(null, config);

			const data = graphQLClient.request(gqlSchema, variables || {});
			return data;
		}

		return null;
	};

	const api = (type, variables, options, headers) => {
		if (name) {
			const config = getConfig(type);
			// console.log('REQUEST ACCESS TOKEN', name, token);

			if (config) {
				let response = null;
				if (type === 'query') {
					if (options.callbackOnSuccess) {
						const graphQLClient = initGraphQLClient(options.token || null, config);

						graphQLClient.request(config.gql.schema, variables)
							.then((data) => {
								if (options.callbackOnSuccess) options.callbackOnSuccess(data, variables);
							})
							.catch((err) => {
								const e = parseGQLError(err);
								if (options.callbackOnError) options.callbackOnError(e, variables);
							});
					} else {
						const opts = { ...options };

						// If no custom error handle, set default method!
						opts.onError = (err) => {
							const e = parseGQLError(err);
							if (options.onError) options.onError(e, variables);
						};

						response = useQuery(requestName || name, async () => request(config.gql.schema, variables, config), opts); // object is returned
					}

					/* ex.
					const { query } = useGQL('webshop>product_list');
					const [list, setList] = useState(null);
					const { isFetched, data } = query();

					useEffect(() => {
						if (list === null && isFetched === true && data && data.request) {
							setList(data.request);
						}
					}, [isFetched]);
					*/
				} else {
					response = mutateAsync({ gqlSchema: config.gql.schema, variables, config }); // promise is returned

					/* ex.
					const { mutation } = useGQL('webshop>checkout');
					const submitGQL = (variables, callback = null) => {
						clearErrors();

						mutation(variables).then(({ request, validation }) => {
							if (validation) Object.keys(validation).map(key => setError(key, { message: validation[key], shouldFocus: true }));
							else if (typeof callback === 'function') callback(request);
						});
					};
					*/
				}

				return response;
			}
		}

		return {
			status: 'error load request config',
			isError: true,
			error: `Can not load request ${name}`,
			isSuccess: false,
			isFetching: false,
			isLoading: false
		};
	};

	const query = (variables = {}, options = {}, headers = {}) => {
		return api('query', variables, options, headers);
	};

	const mutation = (variables = {}, options = {}, headers = {}) => {
		return api('mutation', variables, options, headers);
	};

	return { query, mutation, getConfig };
};

export default useGQL;
