import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>items'
	},

	gql: {
		schema: gql`
			query AuctionItems(
				$real_estate_id: ID,
				$visibility: [ID],
				$auction_status_id: Int,
				$auction_type_id: Int,
				$subtype_id: [ID],
				$type_id: ID,
				$geo_location_level1_id: ID,
				$geo_location_level2_id: ID,
				$geo_location_level3_id: ID,
				$price: [String],
				$auction_expire_soon: String,
				$auction_share_sale_all: String,
				$auction_sequence: [ID],
				$auction_offer_type: [ID],
				$real_estate_group_id: String,
				$related: Boolean,
				$owned: Boolean,
				$order_by: [String],
				$page: Int,
				$per_page: Int,
				$after_security_deposit_before_event: Boolean,
				$before_security_deposit: Boolean
			) {
				request(
					real_estate_id: $real_estate_id,
					visibility: $visibility,
					auction_status_id: $auction_status_id,
					auction_type_id: $auction_type_id,
					subtype_id: $subtype_id,
					type_id: $type_id,
					geo_location_level1_id: $geo_location_level1_id,
					geo_location_level2_id: $geo_location_level2_id,
					geo_location_level3_id: $geo_location_level3_id,
					price: $price,
					auction_expire_soon: $auction_expire_soon,
					auction_share_sale_all: $auction_share_sale_all,
					auction_sequence: $auction_sequence,
					auction_offer_type: $auction_offer_type,
					real_estate_group_id: $real_estate_group_id,
					related: $related,
					owned: $owned,
					order_by: $order_by,
					page: $page,
					per_page: $per_page,
					after_security_deposit_before_event: $after_security_deposit_before_event,
					before_security_deposit: $before_security_deposit
				) {
					list {
						real_estate_id
						owner_user_id
						codeid
						geodeticid
						transaction_nm
						auction_type_id
						auction_status_id
						real_estate_group_id
						specifications
						uri_segment
						location
						size
						price
						auction_start_countdown_days
						security_deposit_due_dt
						image_filepath_url
						saved
						subscribed
						live_auction_start_dtm
					}
					pageInfo {
						per_page
						segment
						page
						pages_cnt
						page_cnt
						total_cnt
					}
				}
			}
		`
	}
};

export default request;
