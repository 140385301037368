import { useState } from 'react';
import Router from 'next/router';

function useRedirect() {
	const [list] = useState({
		pricelist: '/cenik',
		auctionList: '/drazbe'
	});

	const redirect = (rId) => {
		const url = getUri(rId);

		if (url !== null) {
			window.scrollTo(0, 0);
			Router.push(url);
		}
	};

	const getUri = (rId) => {
		let url = null;

		if (list[rId]) {
			url = list[rId]; // ex. 'pricelist' (id url path)
		} else if (rId[0] === '/') {
			url = rId; // ex. /cenik (real url path)
		}

		return url;
	};

	return { redirect, getUri };
}

export default useRedirect;
