import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>log_bid'
	},

	gql: {
		schema: gql`
			query AuctionLogBid(
				$real_estate_id: ID,
				$live_auction_is_spectator: ID
			) {
				request(
					real_estate_id: $real_estate_id,
					live_auction_is_spectator: $live_auction_is_spectator
				) {
					real_estate_id
					user_id
					amount
					amount_original
					created_dtm
				}
			}
		`
	}
};

export default request;
