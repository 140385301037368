const request = {
	validation: {
		detail_similar_auction: {
			required: false,
			validator: null
		},
		detail_auction_result: {
			required: false,
			validator: null
		},

		detail_reminder_7days: {
			required: false,
			validator: null
		}
	},

	gql: {
		schema: null
	}
};

export default request;
