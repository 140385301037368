import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/loader';

const LoaderContext = createContext();
export default LoaderContext;

export function LoaderProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		loadedCnt: 0, // when cnt = 3 (config, language, coderegister), then loaded is turned to "true"
		loaded: false,

		// Loaders data (synced with server)
		language: {},
		isLanguageLoaded: false,
		config: {},
		isConfigLoaded: false,
		coderegister: {},
		isCoderegisterLoaded: false
	});

	return (<LoaderContext.Provider value={{ data, dispatch }}>{children}</LoaderContext.Provider>);
}

LoaderProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(LoaderContext);
