import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>item'
	},

	gql: {
		schema: gql`
			query AuctionItem(
			  $real_estate_id: ID,
			  $uri_segment: String
			) {
			  request(
			    real_estate_id: $real_estate_id,
			    uri_segment: $uri_segment
			  ) {
			    real_estate_id
				uri_segment
				codeid
				geodeticid
				transaction_id
			    transaction_nm
				real_estate_group_id
				type_id
			    type_nm
				subtype_id
			    subtype_nm
				geo_location_level1_id
			    geo_location_level3_id
			    geo_location_level4_id
			    location
			    address
			    postal_code_id
			    latitude
			    longitude
			    codeid
			    geodeticid
				geodetic_price
			    year_build
			    year_adaptation
			    description
			    auction_sequence_nm
			    auction_status_nm
				auction_offer_type_id
			    auction_offer_type_nm
			    price
				price_m2
				price_step
				size
				size_calculated
				is_street_view
				google_street_view_heading
				google_street_view_pitch
				google_street_view_fov
				url_website
				primary_agent_nm
				auction_type_id
			    security_deposit
			    security_deposit_due_dt
				auction_source_id
				auction_caseid
				auction_status_id
				auction_location
			    auction_share_sale
				auction_start_dtm
			    auction_start_dt
			    auction_start_time
			    auction_start_countdown
				auction_start_countdown_days
				auction_start_countdown_seconds
				auction_valuation_value
				auction_valuation_value_m2
				auction_valuation_dtm
				live_auction_status,
				live_auction_min_value
				live_auction_min_value_text
				live_auction_result_id
				live_auction_start_dtm
				live_auction_start_countdown_seconds
				live_auction_end_dtm
				live_auction_is_spectator
				live_auction_is_applications
				live_auction_application_due_dtm
				live_auction_application_due_dtm_countdown
				live_auction_application_due_dtm_countdown_seconds				
			    contact
			    specifications
			    location
			    real_estate_additional_listtext
			    real_estate_luxury_listtext
			    real_estate_room_listtext
			    real_estate_heating_listtext
			    real_estate_connector_listtext
			    auction_additional_listtext
				siblings {
					sequence_no
					geodeticid
					geodeticid_detail_url
					geodeticid_map_url
			      	type_nm
			      	subtype_nm
			      	size
			      	year_build
			      	year_adaptation
			      	floor_nm
			      	floor_cnt_nm
				}
				disclaimer {
					text
				}
			    nearby {
			      	nearby_type_id
					nearby_type_nm
			      	distance
			    }
			    exposed_data
			    image {
			      file_system_nm_pub
			      filepath_url
			      filepath_url_preview
			    }
			    document {
					document_type_id
					document_type_nm
			      	file_system_nm_pub
			      	filepath_url
					description
			    }
				saved
				meta_title
			    meta_description
			    meta_keywords
			    meta_image
			  }
			}
		`
	}
};

export default request;
