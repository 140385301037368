import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>application_delete'
	},

	gql: {
		schema: gql`
			mutation AuctionApplicationDelete(
				$auction_application_id: ID
			) {
				request(
					auction_application_id: $auction_application_id
				) {
					status
				}
			}
		`
	}
};

export default request;
