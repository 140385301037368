import { gql } from 'graphql-request';

const request = {
	request: {
		name: 'auction>related'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionRelated(
				$active: Int,
			  $real_estate_id: ID,
				$real_estate_related_id: ID,
				$action_id: ID,
				$type: String
			) {
				request(
					active: $active,
			    real_estate_id: $real_estate_id,
					real_estate_related_id: $real_estate_related_id,
					action_id: $action_id,
					type: $type
				) {
					real_estate_id
					real_estate_related_id
					active
					action_id
					type
				}
			}
		`
	}
};

export default request;
